<template>
  <div class="relative z-50">
    <div
      :class="{
        'bg-cream': options?.cream,
        'lg:py-8': !options?.absolute,
        'lg:absolute lg:inset-x-0 lg:top-0 lg:my-12': options?.absolute,
        'bg-gray pb-12 max-md:inset-0 max-md:h-screen max-md:overflow-auto lg:bg-transparent': isNavOpen,
      }"
    >
      <div class="container">
        <!-- Desktop -->
        <nav
          class="hidden rounded-2xl p-6 lg:-mx-6 lg:block"
          :class="options?.transparent ? 'bg-transparent py-2' : 'bg-white'"
        >
          <ul class="flex h-full items-center gap-x-6">
            <li class="flex items-center">
              <NuxtLink :to="{name: 'index'}">
                <IconsLogo
                  class="w-48"
                  :class="{'fill-white': options?.light}"
                />
              </NuxtLink>
            </li>

            <li
              v-for="link in baseMenu"
              :key="link.text"
              class="odd:mr-auto even:ml-auto"
            >
              <NuxtLink
                :class="{'text-white': options?.light, 'btn btn-sm btn-primary': link.breakPoint}"
                :to="'/' + link.url"
              >
                {{ link.text }}
              </NuxtLink>
            </li>

            <li class="flex items-center gap-x-6">
              <a
                rel="noopener noreferrer nofollow"
                href="https://mijn.westerduin.nl/account/login"
                class="btn btn-sm btn-block !text-base font-normal"
                :class="{'btn-white': options?.transparent}"
              >
                Log in
              </a>
            </li>
          </ul>
        </nav>

        <!-- Mobile -->
        <div
          v-show="!isNavOpen"
          class="py-6 lg:hidden"
        >
          <div class="flex items-center justify-between gap-4">
            <NuxtLink to="/">
              <IconsLogo class="w-32" />
            </NuxtLink>
            <div class="flex flex-row gap-2">
              <NuxtLink
                to="/open-sollicitatie"
                class="btn btn-xs btn-primary btn-block font-normal"
              >
                Upload CV
              </NuxtLink>
              <button
                class="focus-yellow rounded-md p-2"
                @click="openNav"
              >
                <span class="sr-only">Open menu</span>
                <IconsHamburger class="w-8" />
              </button>
            </div>
          </div>
        </div>

        <!-- Mobile open menu -->
        <div
          v-show="isNavOpen"
          v-body-scroll-lock="isNavOpen"
          class="py-5 lg:hidden"
        >
          <div class="flex items-center justify-between gap-4 border border-b-offGray pb-6">
            <NuxtLink
              to="/"
              @click="closeNav"
            >
              <IconsLogo class="w-32 fill-white" />
            </NuxtLink>
            <div class="flex items-center gap-2">
              <NuxtLink
                class="btn btn-xs btn-primary btn-block font-normal"
                to="/open-sollicitatie"
                @click="closeNav"
              >
                Upload CV
              </NuxtLink>
              <button
                class="focus-yellow rounded-md p-2"
                @click="closeNav"
              >
                <span class="sr-only">Sluit menu</span>
                <IconsClose class="w-8 stroke-white" />
              </button>
            </div>
          </div>

          <NavigationMobileBlock
            class="text-base font-medium text-white"
            :menu-item="{children: baseMenu}"
            @link-clicked="closeNav"
          />

          <a
            rel="noopener noreferrer nofollow"
            href="https://mijn.westerduin.nl/account/login"
            target="_blank"
            class="btn btn-xs btn-white btn-block mb-12 font-normal"
          >Log in</a>

          <NavigationMobileBlock
            v-for="menu in menus?.['Footer']?.items ?? []"
            :key="menu.text"
            :menu-item="menu"
            @link-clicked="closeNav"
          />

          <social-links-bar
            v-if="settings"
            :links="settings"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {MenuItem} from '~/graphql/graphql';

const baseMenu: (MenuItem & {
  breakPoint?: boolean
})[] = [
  {text: 'Over ons', url: 'over-westerduin'},
  {text: 'Vacatures', url: 'vacatures'},
  {text: 'Upload je CV', url: 'open-sollicitatie', breakPoint: true},
  {text: 'Voor werkgevers', url: 'werkgevers'},
];
const {settings} = useSofieSettings();
const {menus} = useSofieMenus();

defineProps<{
  options?: {
    transparent?: boolean
    light?: boolean
    absolute?: boolean
    cream?: boolean
  }
}>();

const isNavOpen = ref(false);
const openNav = () => isNavOpen.value = true;
const closeNav = () => isNavOpen.value = false;
</script>
