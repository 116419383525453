<template>
  <div class="lg:p-6">
    <footer class="rounded-t-2xl bg-yellow lg:rounded-b-2xl">
      <div class="container flex flex-col gap-y-8 py-10 lg:py-20">
        <div class="flex flex-col justify-evenly gap-8 lg:flex-row">
          <div class="flex-1">
            <IconsLogo class="w-40" />
            <div>
              <p class="mt-4 hidden lg:block">
                Uitzendadministratie
              </p>
              <p class="mt-1 hidden lg:block">
                <a href="tel:0342 23 11 11">0342 23 11 11</a>
              </p>
            </div>
            <div>
              <p class="mt-4 hidden lg:block">
                Marketing en Financiën
              </p>
              <p class="mt-1 hidden lg:block">
                <a href="tel:0342 23 11 10">0342 23 11 10</a>
              </p>
            </div>
          </div>
          <template v-if="menus">
            <div
              v-for="m in menus['Footer']"
              :key="m.label"
              class="flex flex-1 flex-col"
            >
              <h2 class="font-bold">
                {{ m.label }}
              </h2>
              <nav>
                <ul>
                  <li
                    v-for="link in m.children"
                    :key="link.url"
                    class="mt-4"
                  >
                    <NuxtLink :to="link.url">
                      {{ link.label }}
                    </NuxtLink>
                  </li>
                </ul>
              </nav>
            </div>
          </template>

          <div>
            <FormKit
              type="select"
              name="location"
              placeholder="Kies je vestiging"
              label="Vestigingen"
              input-class="lg:py-3.5 mt-4"
              :options="branchOptions"
              @input="routeToContactPage"
            />
            <img
              src="~/assets/img/abu-keurmerk.png"
              alt="ABU-keurmerk"
              class="h-10 w-auto"
            >
          </div>
        </div>
        <hr>
        <div class="flex flex-col items-center justify-between gap-8 lg:flex-row lg:gap-0">
          <div class="text-center lg:text-left">
            <ul class="-ml-3 flex divide-x">
              <li>
                <NuxtLink
                  :to="{name: 'slug', params: {slug: 'privacy-statement'}}"
                  class="px-3 text-xs lg:text-sm"
                >
                  Privacy statement
                </NuxtLink>
              </li>
              <li>
                <NuxtLink
                  :to="{name: 'slug', params: {slug: 'cookie-statement'}}"
                  class="px-3 text-xs lg:text-sm"
                >
                  Cookie statement
                </NuxtLink>
              </li>
            </ul>
          </div>
          <SocialLinksBar
            v-if="settings"
            dark
            :links="settings"
          />
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts">
import type {Vestiging} from '~/graphql/graphql';
import branchQuery from '~/graphql/queries/branch.gql';

const {settings} = useSofieSettings();
const {menus} = useSofieMenus();
const {result: branchResult} = useQuery(branchQuery);
const branches = computed<Vestiging[]>(() => branchResult.value?.branch ?? []);

const branchOptions = computed(() => {
  return branches.value.map((branch) => {
    return {
      value: branch.slug,
      label: branch.title,
    };
  });
});

const routeToContactPage = (slug: string) => {
  useRouter().push({name: 'contact-slug', params: {slug}});
};
</script>
